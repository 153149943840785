import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import core components
import { Header } from 'components/pages/catalog';
import { LinkButton, ImageBanner } from 'components';
import { CardList } from 'components/CardList';
import { Layout, Container } from 'layouts';
import { queryHelper, nodeHelper } from 'helpers';
import { Wrapper } from './index';

export const StyledBackgroundImage = styled.div`
  display: flex;

  margin-bottom: 45px;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.1);
  overflow: hidden;

  @media all and (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 0 20px 20px;
  }
`;

const LatestPage = props => {
  const {
    data: { topBanner, latestCards },
  } = props;
  let nodes = latestCards.edges
    .map(edge => edge.node)
    .filter(node => node.relationships.field_category.name !== 'Blank');
  return (
    <Layout title="Latest Greeting Cards">
      <Container>
        <StyledBackgroundImage>
          <GatsbyImage image={getImage(queryHelper.getGatsbyImageData(topBanner))} alt=""/>
        </StyledBackgroundImage>
        <Wrapper>
          <LinkButton text="Back to Home" link="/" />
        </Wrapper>
        <Header pageName="Select a design" />
        <CardList photos={nodeHelper.toCards(nodes)} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query MyLatestPageQuery {
    topBanner: nodeBanner(title: {eq: "catalogTopBanner"}) {
      drupal_id
      title
      field_display_title
      field_description
      field_url
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    latestCards: allCommerceProductGreetingCards(
      limit: 102
      sort: {drupal_internal__product_id: DESC}
      filter: {relationships: {field_category: {name: {ne: null}}}}
    ) {
      edges {
        node {
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [WEBP]
                    layout: CONSTRAINED
                  )
                }
              }
            }
            field_category {
              name
            }
          }
          title
          drupal_id
        }
      }
    }
  }
`;
export default LatestPage;
