import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Search } from '../../sections';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 20px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  line-height: 38px;
  margin-bottom: 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => props.theme.textSize.m};
    line-height: normal;
  }
`;

const Header = props => (
  <Wrapper>
    <Title>{props.pageName}</Title>
    {props.searchable && <Search />}
  </Wrapper>
);
Header.propTypes = {
  searchable: PropTypes.bool,
};
Header.defaultProps = {
  searchable: false,
};
export default Header;
